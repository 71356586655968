.st-layout {
  height: 100vh;

  .st-sider {
    background-color: #232630;
  }

  .st-content {
    background-color: #f5f8fc;
    z-index: 1;
  }
}

.navbarLogo {
  margin: 0px 8px 0px 8px !important;
}
.CropwiseLogo {
  text-align: center;
}

.syt-antd-popover-inner-content {
  padding: 5px 0px 20px 0px !important;
}

.cw-deck-of-apps-footer .syt-antd-divider-horizontal {
  margin: 12px 0;
}

.syngenta-ant-layout-sider-children {
  height: 100vh !important;
}

.syngenta-ant-menu-inline-collapsed {
  width: auto !important;
}

.submenuName {
  margin-top: 7px !important;
}

.syt-antd-avatar-circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: rgb(77, 81, 101);
  display: block;
  cursor: pointer;
  background: rgb(243, 244, 246);
  font-weight: 600;
  text-align: center;
}

.navbarDivider {
  display: none;
}

.syngenta-ant-layout-sider-trigger {
  background-color: #14151c !important;
}

.syngenta-ant-layout-sider,
.syngenta-ant-menu.syngenta-ant-menu-dark {
  background-color: #232630 !important;
}
.main-container {
  overflow-y: auto;
  overflow-x: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbarUserArea {
  padding-right: 0px !important;
  margin-top: 7px !important;
}

.loader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, white 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, white);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: spin 1s infinite linear;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: 10px 0 10px 0;
}

.app-root {
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
}

.defaultButton {
  border: 1px solid #c2c7d0;
  border-radius: 4px;
  padding: 8.1px 16px !important;
  cursor: pointer;
  &:hover {
    border: 1px solid #c2c7d0;
  }
}

.syngenta-ant-input {
  color: #2f3031;
  border-color: #c2c7d0 !important;
}

.syngenta-ant-input:focus {
  color: #2f3031;
  border-color: #c2c7d0 !important;
}

.syngenta-ant-input-focused {
  color: #2f3031;
  border-color: #c2c7d0 !important;
}

.syngenta-ant-input-affix-wrapper {
  border: 1px solid #c2c7d0 !important;
}

.syngenta-ant-input-affix-wrapper:focus {
  border: 1px solid #c2c7d0 !important;
  box-shadow: none !important;
}

.syngenta-ant-input-affix-wrapper-focused {
  border: 1px solid #c2c7d0 !important;
  box-shadow: none !important;
}

.syngenta-ant-layout-content {
  padding: 24px;
}
.primaryButton {
  border: unset;
}
.syt-antd-btn {
  padding: 9.1px 16px;
}
.ant-pagination {
  justify-content: center;
  .ant-pagination-item {
    border-radius: 20px !important;
    background-color: #ffffff !important;
  }
  .ant-pagination-item-active {
    a {
      color: #ffffff;
    }
    background-color: #1677ff !important;
  }
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #c2c7d0 !important;
  border-right: 1px solid #c2c7d0 !important;
  border-left: 1px solid #c2c7d0 !important;
}
.ant-table-thead > tr > th {
  border: 1px solid #c2c7d0 !important;
}
.syngenta-ant-menu-item-selected {
  background-color: black !important;
}
.ant-form-item-label {
  padding: 0 !important;
}
.syt-antd-btn-default {
  background-color: #ffffff;
}
.ant-divider {
  border-block-start: 1px solid #bdc2cf;
  margin: 10px 0;
}
.cw-page-container {
 max-height: 100vh;
}
.main-content {
  max-height: 91vh;
  overflow: hidden auto;
}

.custom-collapse {
  &.ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 12px;
      }
      .ant-collapse-content {
        padding: 0 12px;
        border-radius: 8px;
        .ant-collapse-content-box {
          padding: 0 12px 12px 0;
        }
      }
    }
  }
}

@ant-prefix: syngenta-ant;