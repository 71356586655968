.forms-menu {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f3f4f6;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px 0px;
  &__heading {
    font-weight: 600;
    text-align: center;
    margin: 16px 0;
  }
  &__collapse {
    height: 70vh;
    border-radius: 0;
    overflow-y: scroll;
    border: none;
    background-color: #ffffff;
    &__panel {
      margin: 8px 12px 8px 12px;
      border: 1px solid #f3f4f6;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      li {
        padding: 4px 0;
      }
    }
  }
  &__btn-container {
    padding: 16px 12px 16px 12px;
    border-top: 1px solid #dfe2e7;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0px 0px 8px 8px;
    &__add-btn {
      width: 100%;
      border: 1px solid #c2c7d0;
      border-radius: 4px;
    }
  }
}
.forms-menu,
.farmsFields-menu {
  .ant-collapse {
    .ant-collapse-content {
      border: none;
      .ant-collapse-content-box {
        padding: 0px 15px;
      }
    }
  }
}
.modal-btn:hover {
  background-color: green;
}
.ant-checkbox-group {
  display: inline-flex;
  column-gap: 8px;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  padding-left: 24px;
  margin-bottom: 10px;
}
.checkbox-group-vertical .ant-checkbox-group-item {
  padding: 4px;
  font-size: 12px;
}
.custom__steps .ant-steps-item-title {
  font-size: 14px;
  line-height: normal;
  margin-left: 15px;
}
.custom-form .ant-form-item .ant-form-item-label > label {
  font-size: 16px;
}
.column-fields .title_section {
  margin: 16px 0 16px 0;
}
.custom-collapse .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 20px !important;
  right: 22px;
  font-size: 50px;
}
.farmsFields-menu {
  height: 392px;
  margin-left: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 0px 16px;
  &__container {
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--neutral-neutral-30, #c2c7d0);
    width: 100%;
    overflow-y: auto;
    position: relative;
    .empty-state {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      text-align: center;
      width: 100%;
    }
  }
  &__collapse {
    background-color: #ffffff;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 0;
    &__panel {
      border-radius: 4px;
      box-shadow: none;
      border: 0;
    }
  }
}
.forms-menu
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  word-wrap: break-word;
  width: 90%;
}
.custom-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child,
.custom-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-radius: 0;
}
.custom-table .ant-table-container table > thead > tr > * {
  background: #f3f4f6;
}
.custom-table .ant-table-container table > tbody > tr > td {
  font-size: 12px;
}

.create-new-asmt-btn {
  height: 78vh;

  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  .syngenta-ant-result {
    padding: 12px !important;
  }
}
.height-full {
  height: 100%;
}
.vertical-flex {
  display: flex;
  flex-direction: column;
}
.assesmets {
  height: 100%;
  .vertical-flex;
  .custom-table {
    flex: 1;
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .vertical-flex;
        .ant-table {
          min-height: 575px;
          flex: 1;
        }
      }
    }
  }
}
.farm-country-cirty{
  padding-left:24px ;
}

@ant-prefix: syngenta-ant;