.syt-antd-menu-item svg {
  fill: #696f88;
}
.syt-antd-menu-item-selected svg {
  fill: #ffffff;
}
.syt-antd-menu-item-active svg {
  fill: #ffffff;
}

.syngenta-ant-menu-item-selected .sidebar-icon {
  filter: brightness(0) invert(1);
}

.syngenta-ant-menu-item {
  padding-left: 24px !important;
}

.syngenta-ant-menu-item-selected::before {
  content: " ";
  color: #ffffff;
  position: absolute;
  width: 4px;
  left: 0px;
  top: 4px;
  bottom: 4px;
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
}

.syt-antd-tooltip-inner {
  display: none;
}

@ant-prefix: syngenta-ant;